<template>
  <div class="splitview-container" :key="hasMobileView">
    <splitpanes
      horizontal
      class="default-theme">
      <pane>
        <splitpanes
          :vertical="hasMobileView"
          :horizontal="hasMobileView">
          <pane :size="viewRatio">
            <content-container
              layout="splitview"
              :category="mainCategory">
            </content-container>
          </pane>
          <pane
            class="relative"
            v-if="secondCategory">
            <content-container
              :class="{
                'p-3': addPadding
              }"
              layout="splitview"
              :category="secondCategory"
              @updateType="resetElement"
              @selectElement="selectElement($event)">
            </content-container>
          </pane>
        </splitpanes>
      </pane>
      <pane
        size="14"
        min-size="14"
        max-size="14"
        class="preview"
        v-if="childCategories && childCategories.length > 1">
        <folder-container :horizontal="true">
          <template
            :key="categoryIndex"
            v-for="(category, categoryIndex) in childCategories">
            <folder-element
              :hide-name="true"
              :element="category"
              :splitview-mode="true"
              :emitClickEvent="true"
              :tooltip="category.categoryname"
              @click.prevent="selectElement(category)">
            </folder-element>
          </template>
        </folder-container>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Pane from '../../../external/splitpanes/Pane'
  import Splitpanes from '../../../external/splitpanes/Splitpanes'
  import SplitPaneCSS from '../../../assets/scss/splitpanes.scss'

  export default {
    props: [
      'category',
      'breadcrumb',
    ],
    computed: {
      ...mapGetters({
        hasMobileView: 'app/hasMobileView',
        templateAttribute: 'config/templateAttribute',
      }),
      viewRatio() {
        let viewRatioAttribute = this.category?.attributes?.find(attr => attr.name === 'CMS-SplitView-Ratio')
        if (viewRatioAttribute && viewRatioAttribute.value) {
          return viewRatioAttribute.value.split(':')[0]
        }
        return 50
      },
      addPadding() {
        return this.currentSecondaryType !== 'pdf'
          && this.currentSecondaryType !== 'meeting'
      }
    },
    data() {
      return {
        mainCategory: null,
        secondCategory: null,
        childCategories: null,
        currentSecondaryType: null,
        previousSecondCategory: null,
      }
    },
    async created() {
      // Call child categories of this.category by calling them explicit (workaround for bugs).
      let childCategories = await this.$api.findCategories(this.category.id)
      for (let i = 0; i < childCategories.length; i++) {
        childCategories[i] = await this.$api.findCategory(childCategories[i].id)
      }

      if (childCategories && childCategories.length > 1) {
        this.mainCategory = childCategories[0]
        this.secondCategory = childCategories[1]
        this.childCategories = childCategories.slice(1)
        this.previousSecondCategory = childCategories[1]
      }
    },
    methods: {
      /**
       * Save the previous category first and change it to the new one.
       * @param category
       * @return {Promise<void>}
       */
      async selectElement(category) {
        // Rewrite category to default layout, to display it correctly.
        // This rewrite is necessary because of the inherited effect of Dubidot MAM.
        let attr = category?.attributes?.find(attr => attr.name === 'CMS-Template')
        attr.value = null

        this.previousSecondCategory = this.secondCategory
        this.secondCategory = category
      },
      resetElement($type) {
        this.currentSecondaryType = $type
        if ($type === 'frame') {
          this.secondCategory = this.previousSecondCategory
        }
      }
    },
    components: {
      Splitpanes,
      Pane,
    },
    inject: [
      '$helper',
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .splitview-container {
    @apply
      w-full
      h-full;

    .preview {
      @apply
        px-4
        flex
        items-center
        overflow-y-auto;
    }
  }
</style>